import { Component, OnInit} from '@angular/core';
import { TitleService } from '../title.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  pageTitle = 'Radca prawny Katowice | Porady prawne | Kancelaria Myszor'
  pageDesc = 'Kancelaria Myszor w Katowicach zajmuje się kompleksowymi poradami prawnymi. Specjalizujemy się w tematach odszkodowań, prawie cywilnym i prawie pracy.'

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {
    const elmnt = document.getElementById('startContentSection');
    elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    this.titleService.setTitle(this.pageTitle)
    this.titleService.setMeta(this.pageDesc)
  }
}
