<div id="contact">
    <div class="container">
        <div class="col-2">
            <h1>
                Kontakt
            </h1>
            <p>
                <strong>
                    Kancelaria Radcy Prawnego Barbara Myszor
                </strong>
            </p>
            <p>
                <span class="address"></span>
                <a target="_blank"
                    href="https://www.google.com/maps/place/50%C2%B013'41.0%22N+18%C2%B055'21.5%22E/@50.2280524,18.9204563,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d50.228049!4d18.922645">
                    ul. Owsiana 2b, 40-780 Katowice
                </a> <br>
                <span class="phone"></span> <a href="tel:+48602398671">602 398 671</a><br>
                <span class="email"></span> <a href="mailto:bmyszor@post.pl">bmyszor@post.pl</a><br>
            </p>
            <p>
                Nr rachunku bankowego: <br>
                47 1140 2004 0000 3302 3355 2187
            </p>

            <p>
                NIP: 9540013548
            </p>
        </div>
        <div class="col-2">
            <!-- <figure>
                <img src="../../assets/images/owsiana2b.png">
            </figure> -->

            <iframe class="map"
                src="https://www.openstreetmap.org/export/embed.html?bbox=18.91530275344849%2C50.22485391095245%2C18.929464817047123%2C50.231127245339856&amp;layer=mapnik&amp;marker=50.22799068129939%2C18.922383785247803">
            </iframe>

            <a target="_blank"
                href="https://www.google.com/maps/place/50%C2%B013'41.0%22N+18%C2%B055'21.5%22E/@50.2280524,18.9204563,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d50.228049!4d18.922645">
                Otwórz dużą mapę
            </a>

        </div>
    </div>
</div>