import { Component, OnInit } from '@angular/core';
import { TitleService } from '../title.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  
  pageTitle = 'Kontakt | Kancelaria prawnicza Myszor'
  pageDesc = 'Skontaktuj się z nami i umów się na poradę prawną w Katowicach. ul. Owsiana 2b, 40-780 Katowice.'

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {
    const elmnt = document.getElementById('startContentSection');
    elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    this.titleService.setTitle(this.pageTitle)
    this.titleService.setMeta(this.pageDesc)
  }

}
