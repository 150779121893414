<div id="footer">
    <div class="footer-sentence">
        <div class="container">
            <div class="col-4">
                <h2>
                    OD PONAD 25 LAT
                </h2>
                <h3>
                    SPECJALIZUJEMY SIĘ W SPRAWACH O NAPRAWĘ
                    SZKÓD WYWOŁANYCH EKSPOLATACJĄ GÓRNICZĄ
                </h3>

                <button routerLink="./oferta" (click)="scroll('startContentSection')">SPRAWDŹ OFERTĘ</button>
            </div>

        </div>
    </div>

    <footer>
        <div class="container">

            <!-- <div class="col-4">
                <ul class="footer-nav">
                    <li>
                        <a href="">Strona główna</a>
                    </li>
                    <li>
                        •
                    </li>
                    <li>
                        <a href="">O kancelarii</a>
                    </li>
                    <li>
                        •
                    </li>
                    <li>
                        <a href="">Oferta</a>
                    </li>
                    <li>
                        •
                    </li>
                    <li>
                        <a href="">Kontakt</a>
                    </li>
                </ul>
            </div> -->

            <div class="col-4">
                <p>
                    <a href="/">Kancelaria Radcy Prawnego Barbara Myszor</a><br>
                    Wszelkie prawa zastrzeżone.
                </p>
            </div>
        </div>
    </footer>
</div>