import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { OfferComponent } from './offer/offer.component';
import { ContactComponent } from './contact/contact.component';
import { MainingDamageComponent } from './maining-damage/maining-damage.component';

const routes: Routes = [
  // {path: '', component: HomeComponent},
  {path: '', component: AboutComponent},
  {path: 'szkody-gornicze', component: MainingDamageComponent},
  {path: 'oferta', component: OfferComponent},
  {path: 'kontakt', component: ContactComponent}
]

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
}

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
    RouterModule
  ]
  
})
export class AppRoutingModule { }
