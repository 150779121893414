<div id="offer">
    <div class="container">
        <div class="col-4">
            <h1 class="center">
                Oferta
            </h1>
        </div>

        <div class="col-4">
            <strong>
                Radca prawny Katowice – oferta
            </strong>
            <p>
                Oferujemy profesjonalną i kompleksową obsługę prawną osób fizycznych jak i firm – zarówno spółek prawa
                handlowego, jak i przedsiębiorców prowadzących działalność gospodarczą.
                <br>
                Klientów reprezentujemy w toku postępowania przedsądowego (mediacyjnego, ugodowego) oraz w toku
                postępowania sądowego. Oceniamy roszczenia, zgłaszamy szkodę, gromadzimy materiał dowodowy, prowadzimy
                negocjacje ugodowe, kierujemy sprawy do Sądu.
            </p>

        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>
            
            
            <strong>
                Kompleksowe porady prawne – Katowice
            </strong>
            <p>
                Nasza Kancelaria radcy prawnego w Katowicach to wieloletnie doświadczenie i aktualna wiedza.
                Indywidualnie podchodzimy do każdej sprawy, rozpatrujemy wszelkie szanse i zagrożenia oraz
                reprezentujemy klientów podczas postępowania przedsądowego i sądowego. Wspieramy zarówno osoby fizyczne,
                jak i firmy, niezależnie od formy prowadzonej działalności. Nasze usługi doradcze obejmują pomoc w
                zakresie prawa cywilnego, prawa pracy oraz prawa gospodarczego.
            </p>
            <p>
                Zapewniamy kompleksowe porady prawne w Katowicach – naszą siedzibę znajdziesz przy ul. Owsianej 2b – a
                także oferujemy doradztwo m.in. w zakresie szkód górniczych na terenie całego Śląska, m.in. w Rudzie
                Śląskiej, Mikołowie, Chorzowie, Rybniku, Bytomiu czy Bieruniu. Dowiedz się więcej o zakresie pomocy
                prawnej w naszej Kancelarii!
            </p>
        </div>

        <div class="col-4">
            <div class="paragraph-ico"></div>
            <h2>
                <strong>
                    Pomoc prawna świadczona przez Kancelarię obejmuje w szczególności:
                </strong>
            </h2>
        </div>

        <div class="list">
            <div class="col-2">
                <div class="li">
                    Udzielanie porad i konsultacji prawnych
                </div>
            </div>
            <div class="col-2">
                <div class="li">
                    Zapewnienie stałej obsługi prawnej
                </div>
            </div>
            <div class="col-2">
                <div class="li">
                    Sporządzanie opinii prawnych
                </div>
            </div>
            <div class="col-2">
                <div class="li">
                    Sporządzanie pism procesowych, umów, ugód
                </div>
            </div>
            <div class="col-2">
                <div class="li">
                    Pomoc w prowadzeniu negocjacji i rokowań
                </div>
            </div>
            <div class="col-2">
                <div class="li">
                    Zastępstwo procesowe przed sądami powszechnymi
                </div>
            </div>


            <div class="col-4">
                <h2>
                    <strong>
                        Radca prawny w Katowicach – specjalizacja:
                    </strong>
                </h2>
            </div>

            <div class="icons">

                <div class="col-05-c">
                    odszkodowania
                </div>
                <div class="col-05-md">
                    szkody górnicze
                </div>
                <div class="col-05-cl">
                    prawo cywilne
                </div>
                <div class="col-05-wl">
                    prawo pracy
                </div>
                <div class="col-05-el">
                    prawo gospodarcze
                </div>

            </div>

            <div class="col-4">
                
                <div class="paragraph-ico"></div>

                <strong>
                    Odszkodowania
                </strong>
                <p>
                    Pomagamy w uzyskiwaniu należnych odszkodowań i zadośćuczynień. Analizujemy sprawę i oceniamy szanse
                    na uzyskanie odszkodowania oraz wskazujemy potencjalne ryzyka, prowadzimy negocjacje, a gdy zajdzie
                    taka konieczność – zapewniamy reprezentację i wsparcie podczas walki sądowej.
                </p>


                <div class="paragraph-ico"></div>
            
                <strong>Szkody górnicze</strong>
                <p>
                    Jedną z naszych specjalności są szkody górnicze W nieruchomościach gruntowych, budynkowych,
                    infrastrukturze kolejowej,
                    wodociągowej, w
                    zakresie zawodnienia nieruchomości, zwrot nakładów poniesionych na zabezpieczenie obiektów
                    budowlanych
                    przed
                    wpływami eksploatacji górniczej, zwrotu kosztów rozbiórki.
                </p>

                <div class="paragraph-ico"></div>
                

                <strong>
                    Prawo cywilne
                </strong>
                <p>
                    Reprezentujemy naszych klientów w sprawach z zakresu prawa cywilnego (m.in. dochodzenie
                    zadośćuczynienia, podział majątku, rozwody, zachowek), w tym w sprawach dotyczących ochrony
                    własności. Zapewniamy również porady prawne w zakresie prawa cywilnego.
                </p>

                <div class="paragraph-ico"></div>
                

                <strong>
                    Prawo pracy
                </strong>
                <p>
                    Nasza kancelaria radcy prawnego w Katowicach zapewnia pomoc w dochodzeniu praw pracowników – m.in. w
                    przypadku bezprawnego wypowiedzenia umowy o pracę, braku wypłaty czy mobbingu. Udzielamy również
                    porad prawnych pracodawcom.
                </p>

                <div class="paragraph-ico"></div>
                

                <strong>
                    Prawo gospodarcze
                </strong>
                <p>
                    Zakres naszych porad prawnych w Katowicach obejmuje również prawo gospodarcze. Pomagamy
                    przedsiębiorcom m.in. zakładaniu i przekształcaniu działalności oraz spółek, analizie, opiniowaniu,
                    a także przygotowywaniu umów i kontraktów. Zapewniamy także obsługę organów podmiotów gospodarczych
                    i pełne zastępstwo przed sądami rejestrowymi.
                </p>
            </div>

        </div>
    </div>