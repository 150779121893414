import { Component, OnInit } from '@angular/core';
import { TitleService } from '../title.service';

@Component({
  selector: 'app-maining-damage',
  templateUrl: './maining-damage.component.html',
  styleUrls: ['./maining-damage.component.css']
})
export class MainingDamageComponent implements OnInit {

  pageTitle = 'Szkody górnicze i odszkodowania | Kancelaria prawnicza Myszor'
  pageDesc = 'Kancelaria Myszor świadczy kompelsową pomoc prawną w zakresie odszkodowań od zakładów górniczych za powstałe szkody. Odszkodowania górnicze Śląsk.'

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {
    const elmnt = document.getElementById('startContentSection');
    elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    
    this.titleService.setTitle(this.pageTitle)
    this.titleService.setMeta(this.pageDesc)
  }





}
