<div id="mining-damage">
    <div class="container">
        <div class="col-4">
            <h1 class="center">
                Szkody górnicze
            </h1>
            <h2>
                Na co należy zwrócić uwagę dochodząc naprawienia szkód wywołanych eksploatacją górniczą?
            </h2>
        </div>


        <div class="col-4">
            <h2 class="paragraph-header">podstawa prawna</h2>
            <p>
                Odpowiedzialność przedsiębiorcy górniczego za szkody spowodowane ruchem zakładu górniczego (szkody
                górniczej) regulują następujące przepisy prawne:
            </p>

            <ul class="paragraph-list">
                <li>Ustawa z dnia 23 kwietnia 1964 r. Kodeks cywilny </li>
                <li>Ustawa z dnia 9 czerwca 2011 r. Prawo geologiczne i górnicze </li>
            </ul>


            <p>
                Podejmowanie działalności gospodarczej w zakresie geologii i górnictwa, polegającej na wykorzystywaniu
                zasobów skorupy ziemskiej w tym zwłaszcza wydobywaniu kopalin, wywiera istotny wpływ na dobra znajdujące
                się
                w zasięgu jej oddziaływania. Skutkiem tych oddziaływań jest zaistnienie różnego rodzaju uszkodzeń, czy
                też
                zniszczenie dóbr majątkowych, a w konsekwencji z prawnego punktu widzenia – powstanie szkody,
                objawiającej
                się poprzez uszczerbek majątkowy.
                Przedsiębiorca ponosi odpowiedzialność cywilną z tytułu szkody górniczej w momencie ustalenia związku
                przyczynowo – skutkowego pomiędzy szkodą, a wpływami eksploatacji górniczej jakie zaistniały na skutek
                tego
                ruchu.
                Jeżeli ruch zakładu górniczego odbywa się zgodnie z wymaganiami ustawy, właściciel traci roszczenie
                negatoryjne, a zatem nie może domagać się wstrzymania naruszeń (w istocie ruchu zakładu górniczego).
                Może on
                natomiast domagać się naprawienia szkody wyrządzonej takim ruchem.
                Zgodnie z art. 144 ust.1 ustawy z dnia 9 czerwca 2011 r. prawo geologiczne i górnicze (dalej zwane pgg)
                właściciel nie może sprzeciwić się zagrożeniom spowodowanym ruchem zakładu górniczego, który jest
                prowadzony
                zgodnie z ustawą. Może on jednak żądać naprawienia szkody wyrządzonej tym ruchem, na zasadach
                określonych
                ustawą. Natomiast zgodnie z treścią art. 145 pgg do naprawienia powstałych w ten sposób szkód stosuje
                się
                przepisy kodeksu cywilnego.

            </p>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">uprawnienia poszkodowanego w zakresie dochodzenia naprawienia szkody</h2>
            <p>
                Czyli art. 363 k.c. zgodnie z którym §1 naprawienie szkody powinno nastąpić, według wyboru
                poszkodowanego, bądź przez przywrócenie stanu poprzedniego, bądź przez zapłatę odpowiedniej sumy
                pieniężnej. Jednakże gdyby przywrócenie stanu poprzedniego było niemożliwe albo gdyby pociągało za sobą
                dla zobowiązanego nadmierne trudności lub koszty, roszczenie poszkodowanego ogranicza się do świadczenia
                w pieniądzu.
                § 2. Jeżeli naprawienie szkody ma nastąpić w pieniądzu, wysokość odszkodowania powinna być ustalona
                według cen z daty ustalenia odszkodowania, chyba że szczególne okoliczności wymagają przyjęcia za
                podstawę cen istniejących w innej chwili.

                W związku z powyższym poszkodowany ma prawo wyboru naprawienia szkody.

            </p>
        </div>
        <div class="col-2">
            <figure class="paragraph-img">
                <img src="../../assets/images/broken-wall-2.jpg" alt="">
            </figure>
        </div>
        <div class="col-2">
            <figure class="paragraph-img">
                <img src="../../assets/images/broken-sidewalk-1.jpg" alt="">
            </figure>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">jak ustalić podmiot odpowiedzialny za wyrządzenie szkody?</h2>
            <p>
                Prawo geologiczne i górnicze oparte jest na zasadzie odpowiedzialności sprawczej.
                Za szkodę odpowiada jej sprawca (przedsiębiorca). Jeżeli ustalenie go nie jest możliwe - odpowiada
                przedsiębiorca, który w chwili ujawnienia się szkody był uprawniony do wydobywania kopaliny w granicach
                obszaru górniczego. Jeśli natomiast nie ma przedsiębiorcy odpowiedzialnego za szkodę ani jego następcy
                prawnego, za szkodę odpowiada Skarb Państwa, reprezentowany przez organ nadzoru górniczego. Jeżeli
                natomiast szkoda powstała w wyniku ruchu zakładu górniczego oraz z innych przyczyn, odpowiedzialność
                przedsiębiorcy i pozostałych sprawców jest solidarna.

            </p>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">sposoby naprawienia szkody przysługujące poszkodowanemu</h2>
            <p>
                Od 1 stycznia 2012 r. poszkodowanemu przysługuje prawo wyboru sposobu naprawienia szkody: albo przez
                przywrócenie stanu poprzedniego albo przez zapłatę odpowiedniej sumy pieniężnej.
                Przywrócenie stanu poprzedniego to tzw. restytucja naturalna. Może ona przybrać postać zarówno naprawy
                uszkodzonej rzeczy (usunięcia uszkodzeń), jak i dostarczenia nowej rzeczy. Dla usunięcia ewentualnych
                wątpliwości ustawa wyjaśnia, że przywrócenie stanu poprzedniego może nastąpić m.in. przez dostarczenie
                gruntów, obiektów budowlanych, urządzeń, lokali, wody albo innych dóbr tego samego rodzaju. Jeżeli
                natomiast przedmiotem szkody są grunty rolne (leśne), jej naprawa następuje w drodze rekultywacji,
                stosownie do przepisów o ochronie tych gruntów.

            </p>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">co trzeba spełnić, aby móc wystąpić z roszczeniem przeciwko zakładowi
                górniczemu?</h2>
            <p>
                Dochodzenie roszczeń o naprawienie szkód wyrządzonych ruchem zakładu górniczego odbywa się na gruncie
                prawa cywilnego. Ewentualny spór między poszkodowanym, a przedsiębiorcą podlega rozstrzygnięciu przez
                właściwy sąd powszechny dopiero po wyczerpaniu postępowania ugodowego. Warunek wyczerpania takiego
                postępowania można uznać za spełniony jeśli przedsiębiorca wyraźnie odmówił zawarcia ugody lub jeśli nie
                odpowiedział na wniosek w ciągu 30 dni od daty jego otrzymania. Podjęcie w tym terminie przez
                przedsiębiorcę faktycznych czynności zmierzających do zawarcia ugody jest natomiast wystarczające do
                uznania, że warunek wyczerpania postępowania ugodowego nie został spełniony, nawet jeśli w okresie 30
                dni nie doszło do zawarcia ugody.
                W przypadku wystąpienia na drogę postępowania sądowego wyczerpanie tzw. postępowania mediacyjnego lub
                wyjaśnienie przyczyn jego niepodjęcia wynikającego z art.187§1pkt. 3kpc.

            </p>
        </div>

        <div class="col-2">
            <figure class="paragraph-img">
                <img src="../../assets/images/damaged-build.jpg" alt="">
            </figure>
        </div>
        <div class="col-2">
            <figure class="paragraph-img">
                <img src="../../assets/images/broken-wall.jpg" alt="">
            </figure>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">tzw. ułatwienia, dobrodziejstwa ustawodawcy w zakresie dochodzenia naprawienia
                szkód przez poszkodowanych</h2>
            <p>
                W myśl przepisu art. 96 ust. 1 pkt. 12 Ustawy o kosztach w sprawach cywilnych strona dochodząca
                naprawienia szkód spowodowanych ruchem zakładu górniczego, o których mowa w ustawie Prawo geologiczne i
                górnicze, nie ma obowiązku uiszczenia koszów sądowych. Natomiast w przypadku roszczenia, które Sąd uzna
                za niezasadne, koszty procesu może ponieść strona pozywająca. Dlatego tak ważne jest dokładne
                przeanalizowanie stanu faktycznego sprawy i skorzystanie z profesjonalnej pomocy prawnej.
            </p>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">ile mam czasu na zgłoszenie szkody?</h2>
            <p>
                Roszczenia związane ze szkodami spowodowanymi ruchem zakładu górniczego przedawniają się z upływem 5 lat
                od dnia dowiedzenia się o szkodzie.
            </p>
        </div>

        <div class="col-4">

            <div class="paragraph-ico"></div>

            <h2 class="paragraph-header">co musze wykazać w toku procesu sądowego?</h2>
            <p>
                Dla powstania odpowiedzialności cywilnej z tytułu szkody wyrządzonej ruchem zakładu górniczego niezbędne
                jest łączne zaistnienie trzech przesłanek:
            </p>
            <ul class="paragraph-list">
                <li>zdarzenia, z którym łączy się obowiązek naprawienia szkody,</li>
                <li>powstania szkody,</li>
                <li>związku przyczynowego między zdarzeniem, a szkodą.</li>
            </ul>
            <p>
                Jako zdarzenie należy uznać ruch zakładu górniczego, szkodą natomiast mogą być uszkodzenia budynków (np.
                pęknięcia, osunięcia, odchylenia ścian), ale także gruntów rolnych, leśnych oraz różnego rodzaju
                urządzeń czy instalacji. Związek przyczynowy pomiędzy zdarzeniem a szkodą zachodzi, gdy ruch zakładu
                górniczego miał wpływ na jej powstanie, a więc w praktyce najczęściej jest to sytuacja, gdy pod
                nieruchomością prowadzona była eksploatacja złóż kopalin. Zgodnie z art. 146 pgg, odpowiedzialność za
                szkodę ponosi przedsiębiorca prowadzący ruch zakładu górniczego, wskutek którego wystąpiła szkoda.
                Jeżeli nie można ustalić, kto wyrządził szkodę, odpowiada za nią przedsiębiorca, który w dniu ujawnienia
                się szkody ma prawo prowadzić w obszarze górniczym, w granicach którego wystąpiła szkoda, działalność
                regulowaną ustawą. Jeżeli nie istnieje przedsiębiorca odpowiedzialny za szkodę ani jego następca prawny,
                za szkodę odpowiada Skarb Państwa reprezentowany przez właściwy organ nadzoru górniczego.
                Odpowiedzialność wyżej wymienionych podmiotów opiera się na zasadzie ryzyka, jest więc niezależna od
                bezprawności i można się od niej uchylić, jedynie wykazując, że szkoda powstała na skutek działania osób
                trzecich lub siły wyższej.
            </p>
        </div>
        <div class="col-4">
            <figure class="paragraph-img">
                <img src="../../assets/images/folder2.jpg" alt="">
            </figure>
        </div>
    </div>
</div>