<header>
    <nav>
        <div>
            <ul id="full-screen-menu">
                <li> <a routerLink="./" (click)="scroll('startContentSection')">O kancelarii</a></li>
                <li> <a routerLink="./szkody-gornicze" (click)="scroll('startContentSection')">Szkody górnicze</a></li>
                <li> <a routerLink="./oferta" (click)="scroll('startContentSection')">Oferta</a></li>
                <li> <a routerLink="./kontakt" (click)="scroll('startContentSection')">Kontakt</a></li>
            </ul>
        </div>

        <div id="small-screen-menu">
            <ul>
                <li mat-button [matMenuTriggerFor]="menu" class="small-screen-menu-button"> <a >Menu</a></li>
            </ul>
            <mat-menu #menu="matMenu" yPosition="below">
                <ul class=small-screen-menu-ul>
                    <li> <a routerLink="./" (click)="scroll('startContentSection')">O kancelarii</a></li>
                    <li> <a routerLink="./szkody-gornicze" (click)="scroll('startContentSection')">Szkody górnicze</a></li>
                    <li> <a routerLink="./oferta" (click)="scroll('startContentSection')">Oferta</a></li>
                    <li> <a routerLink="./kontakt" (click)="scroll('startContentSection')">Kontakt</a></li>
                </ul>
            </mat-menu>

        </div>

    </nav>


    <section id="header">
        <img class="logo" src="../../assets/images/kancelaria-myszor.svg" alt="">
        <a routerLink="./">
            <h1>
                <strong>Kancelaria Radcy Prawnego</strong> <br>
                Barbara Myszor <br>
            </h1>
        </a>
    </section>

</header>

<div id="startContentSection"></div>