<div id="about">
    <div class="container">
        <div class="col-2">
            <div class="content-margin">
                <h1>
                    O Kancelarii
                </h1>
                <h2>
                    Łączymy doświadczenie z aktualną wiedzą zapewniając kompleksowe rozwiązanie problemu
                </h2>
            </div>
        </div>


        <div class="col-2">
            <div class="content-margin">
                <p>
                    Zawsze zwracamy uwagę na zagadnienia, które mogą pojawić się w toku prowadzenia sprawy, a których
                    Klient może nie uświadamiać sobie przychodząc z konkretnym problemem. Każdy traktowany jest w sposób
                    indywidualny, a jego sprawa rozpatrywana pod kątem ewentualnych szans i zagrożeń. Radca prawny to
                    zawód zaufania publicznego respektujący ideały i obowiązki etyczne ukształtowane w toku jego
                    wykonywania. Każdy radca prawny świadcząc pomoc zobowiązany jest do zachowania najwyższej
                    staranności, zgodnie z zasadami wiedzy, zaś informacje mu powierzone chronione są tajemnicą
                    zawodową.
                </p>
            </div>
        </div>

        <div class="col-4">

            <div class="content-margin">

                <div class="paragraph-ico"></div>

                <p>
                    Kancelaria Radcy prawnego Barbara Myszor to rodzinna, wielopokoleniowa firma z ponad 25-letnim
                    doświadczeniem. Doradzamy i reprezentujemy naszych Klientów, jesteśmy jedną z pierwszych prywatnych
                    kancelarii, specjalizujących się w zakresie roszczeń odszkodowawczych w tym spowodowanych ujemnymi
                    wpływami eksploatacji górniczej, w szczególności w dochodzeniu odszkodowania za szkody górnicze
                    zarówno w postępowaniu ugodowym jak i w drodze procesu sądowego. Służymy pomocą w zakresie prawa
                    cywilnego, prawa pracy, prawa gospodarczego.
                </p>
            </div>
        </div>
        <div class="collap-off" *ngIf="active">
            <div class="col-4">
                <div class="content-margin collap-button">
                    <span> <button class="btn-on-white" (click)="active = !active">Czytaj więcej...</button></span>
                </div>
            </div>
        </div>

        <div class="collap-on" [ngClass]="active ? 'element-hide' : 'element-visible'">
            <div class="col-4">
                <div class="content-margin">
                    <div class="paragraph-ico"></div>

                    <div class="subcontainer">
                        <div class="col-2">
                            <figure class="paragraph-img">
                                <img src="../../assets/images/myszor.png" alt="">
                            </figure>
                        </div>
                        <div class="col-2">
                            <h2 class="paragraph-header">dlaczego właśnie nasza Kancelaria prawnicza w katowicach?</h2>
                            <p>
                                Kancelaria Radcy prawnego Barbara Myszor zajmuje się szkodami górniczymi na Śląsku
                                na obszarze
                                całego województwa Śląskiego tj. miasta Katowice, Ruda Śląska, Mikołów, Mysłowice,
                                Bytom,
                                Chorzów,
                                Tarnowskie Góry, Bieruń, Gierałtowice, Rybnik. Kancelarię wyróżnia za wysoki poziom
                                świadczonych
                                usług, indywidualnym podejściem do klienta, zrozumieniem oczekiwań Klientów, przy
                                jednoczesnym
                                doradzeniu rzeczywistych możliwości i realności dochodzonego roszczenia w zakresie
                                naprawienia
                                szkody. Kancelaria zajmuje się kompleksowym prowadzeniem sprawy, w związku z
                                powyższym
                                poszkodowani
                                nie muszą stresować się uczestnictwem na rozprawach sądowych. Podstawową funkcją
                                odszkodowania
                                jest
                                kompensacja. Istotą naprawienia szkody jest przywrócenie stanu poprzedniego. Tak
                                więc
                                odszkodowanie
                                powinno przywrócić w majątku poszkodowanego stan rzeczy naruszony zdarzeniem
                                wyrządzającym
                                szkodę. W
                                praktyce sprowadza się to do konieczności ustalenia wartości technicznej składników
                                budowlanych
                                podlegających uszkodzeniu na skutek ruchu zakładu górniczego. Zachęcamy do
                                konsultacji przed
                                zawarciem ugody pozasądowej z przedsiębiorstwem górniczym w celu zapobieżenia
                                ewentualnym
                                negatywnym
                                skutkom warunków wynikających z ugody. Wynikać mogą one z nieznajomości przez
                                poszkodowanych
                                obwiązujących przepisów prawa i sposobów naprawienia szkody. Kancelaria po
                                przeanalizowaniu
                                stanu
                                faktycznego danej sprawy doradzi najlepszy sposób naprawienia szkody, a tym samym
                                rozwiązania
                                sporu.
                                Wystarczy przyjść, opowiedzieć o swoim problemie związanym z powstaniem szkody
                                górniczej, a
                                Kancelaria zajmie się Twoim problemem.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-4">
                <div class="content-margin">
                    <div class="paragraph-ico"></div>

                    <div class="subcontainer">
                        <div class="col-2">
                            <h2 class="paragraph-header">Kancelaria prawnicza Myszor w Katowicach – zakres usług</h2>
                            <p>
                                Nasza Kancelaria prawnicza w Katowicach zapewnia kompleksowe doradztwo oraz pełną
                                obsługę
                                prawną.
                                Nasza oferta skierowana jest do osób fizycznych i firm – niezależnie od formy
                                prowadzenia
                                działalności. Do naszych specjalności zalicza się pomoc w uzyskiwaniu odszkodowań i
                                zadośćuczynień –
                                w tym należnych w związku z wystąpieniem szkód górniczych – a także pomoc w zakresie
                                prawa
                                cywilnego, gospodarczego i prawa pracy.
                            </p>
                            <p>
                                Z jakich usług skorzystasz w naszej Kancelarii? Przede wszystkim – zapewniamy
                                kompleksowe,
                                fachowe
                                porady i konsultacje prawne. Indywidualnie podchodzimy do każdej sprawy, rzeczowo
                                rozpatrując i
                                wskazując klientowi występujące w niej szanse i ryzyka. Ponadto zapewniamy pomoc w
                                negocjacjach,
                                zastępstwo procesowe w sądach powszechnych, a także przygotowujemy ugody, umowy,
                                pisma procesowe
                                oraz sporządzamy opinie prawne w zakresie prawa cywilnego, prawa pracy i prawa
                                gospodarczego.
                                Nasza
                                oferta odzwierciedla również oczekiwania przedsiębiorców – zapewniamy stałą opiekę
                                prawną,
                                doradztwo
                                w zakresie prawa gospodarczego, a także zajmujemy się analizą i opiniowaniem umów.
                            </p>
                        </div>

                        <div class="col-2">
                            <figure class="paragraph-img">
                                <img src="../../assets/images/papers.jpg" alt="">
                            </figure>
                        </div>
                        <div class="col-4">

                            <p>
                                Chociaż jedną z naszych specjalności są szeroko pojęte szkody górnicze, jesteśmy w
                                stanie pomóc
                                Ci w
                                szeregu innych sytuacji i rozwiązać problemy prawne z wielu dziedzin – niezależnie,
                                czy jesteś
                                osobą
                                fizyczną, czy prowadzisz działalność. Zaufaj naszemu wieloletniemu doświadczeniu –
                                odwiedź naszą
                                kancelarię prawniczą w Katowicach przy ul. Owsianej 2b i uzyskaj fachową pomoc
                                prawną!
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>