import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private pageName = 'Kancelaria Myszor';
  private mainTitle = 'Odszkodowania, szkody górnicze, prawo cywilne, prawo pracy, prawo gospodarcze';
  
  constructor(private titleService: Title, private metaService: Meta) { }

  setTitle(title?: string) {

    if (title == undefined) {
      this.titleService.setTitle(this.pageName + ' | ' + this.mainTitle);
    }
    else {
      this.titleService.setTitle(title);
    }
  }

  setMeta(desc?: string) {

    if(desc != undefined)
    {
      this.metaService.updateTag({ name: 'description', content: desc})
    }
    
  }
}
