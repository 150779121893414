import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { TitleService } from '../title.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  pageTitle = 'Kancelaria Myszor | Porady prawne | Kancelaria Prawnicza Katowice'
  pageDesc = 'Odszkodowania i szkody górnicze, prawo cywilne, prawo pracy i gospodarcze - to tylko niektóre dziedziny, którymi zajmuje się nasza kancelaria prawnicza w Katowicach.'

  public active: boolean;

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {

    this.active = true;

    this.titleService.setTitle(this.pageTitle)
    this.titleService.setMeta(this.pageDesc)
  }

}
