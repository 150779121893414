<div>

  <section>
    
      <app-home></app-home>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
  </section>


</div>